.datepicker-popper-display {
    z-index: 1500;
    .react-datepicker {
        display: flex;
    }
}

input.react-datepicker-time__input {
    width: 100% !important;
}

.react-datepicker__input-container input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker-wrapper {
    z-index: 0;
}

.app-main__inner>.react-datepicker-popper .react-datepicker__triangle {
    visibility: hidden;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 120px;
}