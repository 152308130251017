@import "../common/scrollbar.scss";


//[대시보드-1] Capacity Compliance > Compliance Dashboard
//1번째 뎁스 - Floor:pnt 리스트창
//[대시보드-1]
//뎁스 1, floor 오른쪽 보드

@include media-breakpoint-down(xs) {
  .floor-table-custom{
      & *{
          font-size: .9rem;
      }
  }
}

.floor-table-custom{
  height: 100%;
  width: 100%;

  & *{
    border: none !important;
  }

  & > div{
      height: 100%;
      width: 100%;

      & .card-header{
          font-size: 1.3rem;
          padding: 25px;
          margin-top: 0;
          height: 12%;
      }
      & .card-body{
          height: 88%;
          padding: 0;
          margin: 0;

          & table{
              display: block;
              font-size: 1rem;
              margin-bottom: 2px;
              height: 100%;
              overflow: auto;

              & thead{
                display: table;
                table-layout: fixed;
                height: 12%;
                width: 100%;

                & tr{
                  width: 33.33333%;
                  & th{
                    color: #a7b4c3;
                    font-weight: normal;
                    padding: 0.6rem 0 0.6rem 25px;
                    &:first-child{
                      width: 40%;
                    }
                  }
                }
              }
              & tbody{
                display: table;
                table-layout: fixed;
                //height: 88%;
                width: 100%;

                & tr{
                  display: table;
                  width: 100%;

                  &:nth-child(2n-1){
                    background: rgba(0,0,0,0.15);
                  }
                  & td{
                    padding: 0 0 0 25px;
                    width: 33.33333%;
                    line-height: 3;

                    &:first-child {
                      width: 44%;
                    }
                    &[collspan] {
                      //display: block;
                      //width: 200%;
                      //text-align: center;
                    }
                  }
                }
                & tr+tr{
                  border-top: 1px solid $indigo-3 !important;
                }
              }
              @include scrollbar(indigo);
           }
      }
  }
}












//[대시보드-3] Contact Tracing > Contact Tracing
//1번째 뎁스 - Tracing list 리스트창
@include media-breakpoint-down(xl) {
  .list-body-custom{
    height: 55vh;
  }
  .list-header-custom{
    height: 5rem;
    justify-content: space-between;
  }
}
@include media-breakpoint-down(md) {
  .list-header-custom{
    height: auto;
    display: block;
    text-align: center;
    & div{
      margin-top: 1rem;
    }
  }
}
@include media-breakpoint-down(sm) {
  .list-header-custom{
    height: auto;
    & div{
      margin-top: 1rem;
      padding:0 !important;
    }
  }
}
@include media-breakpoint-down(xs) {
  .list-header-custom{
    height: auto;
    & > div{
      margin-top: 1rem;
      padding:0 !important;
      display: flex;
      justify-content: space-between;

      & button{
        padding: .5rem .7rem;

        & i{
          transform: scale(.8);
        }
        & span{
          transform: scale(.8);
          margin-right: .3rem;
        }
      }
    }
  }
}

.list-body-custom{
  border: none;
  
  & .rt-table{
    background-color: $indigo-2;

    @include scrollbar(indigo);

    & .rt-thead{
      & .rt-tr{
        & .rt-th{
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        & .rt-th:hover {
          color: rgba(255,255,255,0.6);
        }
        & .rt-th:last-child{
          margin-right: 10px;
        }
      }
    }
    & .rt-tbody{
      & .rt-tr-group{
        border: none;
        & .rt-tr{
          & .rt-td{
            justify-content: center;
          }
        }
      }
      @include scrollbar(indigo);
    }
  }
  & .rt-noData{
    border-radius: 0.25rem;
    background-color: rgba(0,0,0,0.3);
    color: white;
    width: 70%;
    padding: 3rem 0;
    text-align: center;
    font-size: 1rem;
  }
}

//[대시보드-3] Contact Tracing > Contact Tracing
//2번째 뎁스 - Daily Contacts 팝업창

.dailycontact-custom{
    z-index: 1100;
    border-radius: 0.25rem;
    max-width: 80% !important;
    min-width: 80% !important;
    // left: 50% !important;
    // transform: translateX(-50%);
  
  & > div{
    background-color: #3a3f5d !important;
    & .card-header{
      // background-color:rgba(255,255,255,.1) !important;
      // border-bottom: 1px solid rgba(255,255,255,.3);
      background-color: #3a3f5d !important;
      border: none;
    }
  }
}

.contactlist-custom{
  & .toast-header{
    position: relative;
    & button{
      position: absolute;
      right: 0.25rem;
      top: 1rem;
      font-weight: 100;
      font-size: 3rem;
      line-height: 0;
      text-shadow: none;
      & span{
        font-family: NotoSans;
        font-weight: normal;
        font-size: 3rem;
        line-height: 0;
        text-shadow: none;
        padding: 0;
      }
    }
  }
  & .toast-body{
    & .contactlist-wrap{
      border: none;
      margin: 0 -1.75rem -1.75rem -1.75rem;

      & .rt-table{

        & .rt-thead{
          & *{
            border: none;
          }

          & .rt-tr{
            text-align: inherit;
            width: 100%;
            margin: .5rem 0;
            & .rt-th{
              padding: 0 1.75rem;
              max-width: inherit !important;
              & div{
                color: $indigo-font;
                padding: 0;
                font-size: 1rem;
                font-weight: normal;
              }
            }
          }
        }

        & .rt-tbody{
          overflow-x: hidden;
          overflow-y: auto;

          & .rt-tr-group{
            border: none;

            &:nth-of-type(2n-1){
              background: $indigo-7;
            }
            & .rt-tr{
              & .rt-td{
                border: none;
                & div{
                  justify-content: center;
                }
              }
            }
          }
        }
      }

      & .rt-noData{
        border-radius: 0.25rem;
        background-color: rgba(0,0,0,0.3);
        color: white;
        width: 70%;
        padding: 3rem 0;
        text-align: center;
        font-size: 1rem;
      }
    }

  }
}

//[대시보드-3] Contact Tracing > Contact Tracing
//2번째 뎁스 - Contaminated Zones 팝업창
.contaminated-custom{
  & .toast-header{
    position: relative;
    & button{
      position: absolute;
      right: 0.25rem;
      top: 1rem;
      font-weight: 100;
      font-size: 3rem;
      line-height: 0;
      text-shadow: none;
      & span{
        font-family: NotoSans;
        font-weight: normal;
        font-size: 3rem;
        line-height: 0;
        text-shadow: none;
        padding: 0;
      }
    }
  }
  & .toast-body{
    
    
    & .contaminated-wrap{
      border: none;
      margin: 0 -1.75rem -1.75rem -1.75rem;
      
      & .rt-table{
    
        & .rt-thead{
          & *{
            border: none;
          }
  
          & .rt-tr{
            text-align: inherit;
            width: 100%;
            margin: .5rem 0;
            & .rt-th{
              padding: 0 1.75rem;
              max-width: inherit !important;
              & div{
                color: $indigo-font;
                padding: 0;
                font-size: 1rem;
                font-weight: normal;
              }
            }
          }
        }
  
        & .rt-tbody{
          overflow-x: hidden;
          overflow-y: auto;
  
          & .rt-tr-group{
            border: none;
  
            &:nth-of-type(2n-1){
              background: $indigo-7;
            }
            & .rt-tr{
              & .rt-td{
                border: none;
                & div{
                  justify-content: center;
                }
              }
            }
          }
        }
      }
      
      & .rt-noData{
        border-radius: 0.25rem;
        background-color: rgba(0,0,0,0.3);
        color: white;
        width: 70%;
        padding: 3rem 0;
        text-align: center;
        font-size: 1rem;
      }
    }
    
  }
}

//[대시보드-3] Contact Tracing > Contact Tracing
//2번째 뎁스 - Contacts at High Risk 팝업창
.contacthighrisk-custom{
  & .toast-header{
    position: relative;
    & button{
      position: absolute;
      right: 0.25rem;
      top: 1rem;
      font-weight: 100;
      font-size: 3rem;
      line-height: 0;
      text-shadow: none;
      & span{
        font-family: NotoSans;
        font-weight: normal;
        font-size: 3rem;
        line-height: 0;
        text-shadow: none;
        padding: 0;
      }
    }

  }
  & .toast-body{
    & .contacthighrisk-wrap{
      border: none;
      margin: 0 -1.75rem -1.75rem -1.75rem;
      
      & .rt-table{
    
        & .rt-thead{
          & *{
            border: none;
          }
  
          & .rt-tr{
            text-align: inherit;
            width: 100%;
            margin: .5rem 0;
            & .rt-th{
              padding: 0 1.75rem;
              max-width: inherit !important;
              & div{
                color: $indigo-font;
                padding: 0;
                font-size: 1rem;
                font-weight: normal;
              }
            }
          }
        }
  
        & .rt-tbody{
          overflow-x: hidden;
          overflow-y: auto;
  
          & .rt-tr-group{
            border: none;
  
            &:nth-of-type(2n-1){
              background: $indigo-7;
            }
            & .rt-tr{
              & .rt-td{
                border: none;
                & div{
                  justify-content: center;
                }
              }
            }
          }
        }
      }
      
      & .rt-noData{
        border-radius: 0.25rem;
        background-color: rgba(0,0,0,0.3);
        color: white;
        width: 70%;
        padding: 3rem 0;
        text-align: center;
        font-size: 1rem;
      }
    }
    
  }
}


//[대시보드-3] Contact Tracing > Contact Tracing
//2번째 뎁스 - Contact list 리스트창

.contact-list-table-body-custom{
  border: none;
  & *{
    border: none !important;
    font-size: 0.9rem !important;
  }
  
  & .rt-table{
    @include scrollbar(indigo);
    & .rt-thead{
      & *:hover *{
        color: #a7b4c3;
      }
      & .rt-tr{
        padding-right: 10px;
        & .rt-th{
          color: #a7b4c3;
          font-family: NotoSans;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.21;
        }
      }
    }
    & .rt-tbody{
      & .tbody-window{
        & > div{
          & .rt-tr{
            &:nth-of-type(2n-1){
              background: $indigo-5;
            }
            & .rt-td{
              padding: 0;
              color: white;
              font-family: NotoSans;
              font-size: 1rem;
              font-weight: 500;
              justify-content: center;
              text-align: center;
              
              & .progress{
                background-color: $indigo-8 !important;
              }
              & .target-name-column{
                align-items: center;
                text-overflow: ellipsis;
                text-align: center;
                width: 100%;
  
                & i{}
                & span{
                  width: 100%;
                  white-space: space wrap !important;
                }
                & > div{
                  min-width: 1.5rem;
                }
              }
              & span{
                margin: 0 !important;
              }
            }
            
            //프로그래스바 콜룸 칸
            & .progressbar-left-custom{
              & > div{
                justify-content: left !important;
                & span{
                  padding-left: .5rem;
                  white-space: nowrap;
                }
              }
            }
          }
        }
        @include scrollbar(indigo);
      }
    }
  }
  & .rt-noData{
    border-radius: 0.25rem;
    background-color: rgba(0,0,0,0.3);
    color: white;
    width: 70%;
    padding: 3rem 0;
    text-align: center;
    font-size: 1rem;
  }
}