//지도 컨트롤러
//src/MainPages/Dashboards/CapacityCompliance/index.js
.leaflet-control-custom{
  background-color: rgba(60,60,100,0.5);
  color: white;
  border-radius: 0.25rem;
  border: none;
  padding: 10px 10px 1px 10px;
  font-size: 0.8rem;
  text-align: left;
}
