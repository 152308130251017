// Theme White

// Variables

@import "../../../../assets/main/common/common.scss";

//$app-container-bg: #272829;
//$app-sidebar-bg: #ffffff;
//$app-header-bg: #fafbfc;
$app-container-bg: $indigo-4;
$app-sidebar-bg: $indigo-3;
$app-header-bg: $indigo-3;
$app-header-logo-bg: rgba(255, 255, 255, .9);

// Content

.app-theme-white {

  &.app-container {
    background: $app-container-bg;
  }

  .app-sidebar {
    background: $app-sidebar-bg;
  }

  .app-page-title {
    background: rgba(255, 255, 255, .55);
  }

  .app-footer .app-footer__inner,
  .app-header {
    background: $app-header-bg;
  }

  &.fixed-header {
    .app-header__logo {
      // background: rgba($app-header-bg, .1);
      background: $indigo-5;
    }
  }

}