.cm-dialog-custom{
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.cm-content-custom{
  border: none;
  background-color: transparent;
}


.cm-header-custom{
  background-color: #313650;
  color: white;
  border: none;
  border-radius: 10px 10px 0 0;
  & h5{
    font-family: NotoSans;
    font-size: 1.2rem;
    font-weight: bold;
  }
  & button{
    opacity: 0.5;
    color: white;
    font-family: NotoSans;
    font-weight: 100;
  }
  & button:hover{
    opacity: 0.5;
    color: white;
    font-family: NotoSans;
    font-weight: 100;
  }
}
.cm-body-custom{
  border: none;
  color: white;
  background-color: #393e5c;
}
.cm-footer-custom{
  border: none;
  background-color: #393e5c;
  border-radius: 0 0 10px 10px;
}


//이거 $indigo-5 임포트가 왜 안되는지 나중에 확인해서 수정할것
.cm-btn-custom{
  padding: 7px 17px 6px;
  border-radius: 10px;
  border: none;
  background-color: #2b2f45;
  &:hover{
    background-color: #161a23;
  }
}
