
@mixin scrollbar($val) {

    @if $val == indigo {
        &::-webkit-scrollbar {
            height: 10px !important;
            width: 10px !important;
            background-color: rgba(255,255,255,0.2) !important;
            border-radius: 1rem !important;
        }
        &::-webkit-scrollbar-track {
            height: 10px !important;
            width: 10px !important;
            background-color:  #515662 !important;
            border-radius: 1rem !important;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(255,255,255,0.5) !important;
            border-radius: 1rem !important;
            border: none !important;
        }
    }
    
}

