@import "../../main/common/common.scss";
//////////
//버튼 모음
//////////

//작은 버튼
.btn-small{
    min-width: 2rem;
    max-width: 2rem; 
    min-height: 2rem;
    max-height: 2rem;
    display: flex;
    justify-content: center;
}
.btn-big{
    min-width: 10rem;
    font-size: 1rem;
}

//아이콘과 글씨가 있는 pill 버튼
@include media-breakpoint-down(xl) {
    .btn-custom{
        border-radius: $radius-10;
        padding: .5rem 1.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-width: 5rem;
    }
}

.btn-custom.bgpop{
    background-color: rgba(255,100,100,0.5);
    border-color: transparent;
}

//드롭다운 부분 - 덜 동그랗고 남색 버튼
.btn-roundsquare{
    padding: 6px 17px 7px;
    border-radius: 0.5rem;
    border: none;
    min-width: 5rem;

    &:hover{
        background-color: $gray;
        border-color: $gray;
    }
}

//아이콘 한개만 있는 pill 버튼
.btn-pill-one{
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100px;
    position: relative;
}

//3개 붙어있는 버튼
.btn-triple-group{
    margin: 0 0 10px 0;
    & .btn-triple {
        border-radius: 0;
    }
    & .btn-triple:first-child {
        border-radius: 2rem 0 0 2rem;
        border-right: 1px solid rgba(0,0,0,0.2);
    }
    & .btn-triple:last-child {
        border-radius: 0 2rem 2rem 0;
        border-right: 1px solid rgba(0,0,0,0.2);
    }
}

@include media-breakpoint-down(xl) {
    .btn-triple-group{
        & .btn-triple{
            padding: .5rem 5rem;
            font-size: 1rem !important;
            font-weight: normal;
            letter-spacing: .5px;
        }
    }
    
}
@include media-breakpoint-down(md) {
    .btn-triple-group{
        & .btn-triple{
            padding: .5rem 2rem;
            font-size: .8rem !important;
        }
    }
}
@include media-breakpoint-down(xs) {
    .btn-triple-group{
        width: 100%;
        & .btn-triple{
            padding: .5rem 2rem;
            font-size: .8rem !important;
        }
    }
}




//새로고침 버튼
.btn-refresh{
    border: none;
    color: white;
    font-size: .8rem;
    font-weight: 100;
    background-color: rgba(255,255,255,0.2);
    border-radius: 50%;
    //margin-left: 1rem;
    //margin-top: -1px;
    width: 1.6rem;
    height: 1.6rem;
    transition: all .2s;
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
    padding: 1px;

    &:hover{
        background-color: rgba(255,255,255,0.4);
        transition: all .2s;
    }
}



//버튼 색상
.btn-orange-line{
    color: $orange;
    border: 2px solid $orange;
    background-color: transparent;

    &:hover{
        background-color: rgba(255,255,0,0.1);
        border-color: orange;
    }
}

.btn-orange{
    background-color: $orange;
    color: white;
    border: 2px solid $orange;

    &:hover{
        background-color: orange;
        border-color: orange;
    }
}

.btn-lightorange{
    background-color: $rotten-orange;
    color: white;
    border: 2px solid $rotten-orange;

    &:hover{
        background-color: orange;
        border-color: orange;
    }
}


.btn-lightorange-line{
    color: $rotten-orange;
    border: 2px solid $rotten-orange;
    background-color: transparent;

    &:hover{
        background-color: rgba(255,255,0,0.1);
        border: 2px solid $rotten-orange;
    }
}

.btn-indigo{
    background-color: $indigo-3;
    color: white;
    border: 2px solid $indigo-3;
}
.btn-indigo-light{
    background-color: $indigo-1;
    color: white;
    border: 2px solid $indigo-1;
}