@import "../main/common/common.scss";

@font-face {
    font-family: 'NotoSans';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/Noto_Sans/NotoSans-Regular.ttf'),
    url('fonts/Noto_Sans/NotoSans-Bold.ttf'),
    url('fonts/Noto_Sans/NotoSans-BoldItalic.ttf'),
    url('fonts/Noto_Sans/NotoSans-Italic.ttf');
}

.app-main .app-main__inner {
    padding-top: 15px;
}

.form-check {
    padding-left: 0;
}

.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.br-left{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.br-right{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.text-sl{
    font-size: 0.8rem;
    font-weight: normal;
    opacity: 0.5;
}
.text-sm{
    font-size: 0.8rem;
    font-weight: normal;
    opacity: 1;
}
.text-sb{
    font-size: 0.8rem;
    font-weight: bold;
    opacity: 1;
}
.text-ml{
    font-size: 1rem;
    font-weight: normal;
    opacity: 0.5;
}
.text-mm{
    font-size: 1rem;
    font-weight: normal;
    opacity: 1;
}
.text-mb{
    font-size: 1rem;
    font-weight: bold;
    opacity: 1;
}
.text-hl{
    font-size: 1.3rem;
    font-weight: normal;
    opacity: 0.5;
}
.text-hm{
    font-size: 1.3rem;
    font-weight: normal;
    opacity: 1;
}
.text-hb{
    font-size: 1.3rem;
    font-weight: normal;
    opacity: 1;
}


.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.s-100{
    height: 100%;
    width: 100%;
}

.m-0{
    margin: 0;
}
.p-0{
    padding: 0;
}

.vh-10{
    height: 10vh;
}
.vh-20{
    height: 20vh;
}
.vh-30{
    height: 30vh;
}
.vh-40{
    height: 40vh;
}
.vh-50{
    height: 50vh;
}
.vh-60{
    height: 60vh;
}
.vh-70{
    height: 70vh;
}
.vh-80{
    height: 80vh;
}
.vh-90{
    height: 90vh;
}
.vh-100{
    height: 100vh;
}

.logo-login-white {
    background-image: url("images/logo_login_white.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 85px;
}

.logo-wrapper {
    position: absolute;
    bottom: 24PX;
    left: 24px;
    .api-version {
        margin-left: 1rem;
        color: $indigo-3;
    }
    div {
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;
        width: 85PX;
        height: 12PX;
    }
    .logo-vestigo-white {
        background-image: url("images/logo_vestigo_white.svg");
    }
}