
@include media-breakpoint-down(md){

$button-shadow : 0 0 .5rem rgba(0,0,0,0.3);

    .mobile-header-custom{
        & .app-header__menu{
            & button{
                border: none;
                //background-color: transparent !important;
            }
        }

        & .app-header__content{
            padding: 1.5rem 0;
            width: 100%;
            left: 0;
            right: 0;
            top: 66px !important;
            border-radius: 2rem;
            height: auto;
            overflow: visible;
            display: initial;
            background-color: #3a3f5d !important;
            box-shadow: 0px 5px 15px rgba(0,0,0,0.4);
        
            & .app-header-left{
                display: block;
                text-align: center;
                box-sizing: border-box;
                min-width: 100%;
                
                & h5{
                    display: none;
                    // display: inline-block;
                    // vertical-align: middle;
                    // margin: 0;
                    // font-weight: bold;
                    // font-size: 1.2rem;
                    // position: relative;
                    // overflow: visible;
                    //margin-bottom: 1.5rem;
                    
                    // &::after{
                    //     content: '';
                    //     display: block;
                    //     position: absolute;
                    //     top: 150%;
                    //     left: 50%;
                    //     transform: translateX(-50%);
                    //     width: 0.5rem;
                    //     height: 1px;
                    //     background-color:white;
                    //     opacity: .5;
                    // }
                }
                
                
            }
            & .app-header-right{
                justify-content: center;
    
                & .header-dots{
                    margin: 0;
                    position: relative;

                    & .icon-wrapper-alt{
                        width: 4rem;
                        & .icon-wrapper-bg{
                            box-shadow: $button-shadow;
                        }
                    }

                    & .rw-widget{
                        width: 8rem !important;
                        padding: 0 1rem;
                        box-shadow: $button-shadow;

                        & .rw-popup-container{
                            border-radius: 0.25rem;
                            top: calc(100% + 1.5rem + 7px);

                            & .rw-popup-transition{
                                padding: 1rem;
                                & .rw-popup{
                                    box-shadow: none;
                                    & li+li{
                                        margin-top: 1rem;
                                    }
                                }
                            }
                        }
                    }

                    & .dropdown-menu{
                        top: 0 !important;
                        transform: translateY(38%) !important;
                    }
                }
                & .header-btn-lg{
                    display: inline-block;
                    margin: 0 0 0 1rem;
                    background-color: rgba(255, 255, 255, 0.1);
                    box-shadow: $button-shadow;
                    border-radius: 18px;
                    height: 36px;
                    line-height: 36px;
                    padding: 0 1rem;
                    & button{
                        margin-top: -4px;
                        & .user-icon{
                            width: 1.2rem;
                            height: 1.2rem;
                        }
                        & i{
                            margin-left: 0.5rem !important;
                        }
                    }
                    & .dropdown-menu{
                        padding: 1rem;
                        top: calc(3rem + 110px) !important;
                    }

                    &::before{
                        display: none;
                    }
                    & .widget-content{
                        padding: 0;
                    }
                }
            }
        }
    }
    

}