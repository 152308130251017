.statcard-custom{
    background-color: #252c3b;
    padding: 25px 25px 25px 0;
    height: 120px;
}
.m-0{
    margin: 0;
}
.widget-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.widget-wrap div+div{
    margin-top: 5px;
}
.widget-text{
    color: #a7b4c3;
    font-family: NotoSans;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2;
}
.widget-number{
    color: white;
    font-family: NotoSans;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.14;
}


.widget-numbers.text-sm {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.widget-chart {
    padding: 0.5rem;
}

.percentage-card {
    width: 100%;
    height: 3.5rem;
    font-size: 1.6rem;
    text-align: center;
    line-height: 3.3rem;
    font-weight: bold;
}

.percentage-card.bg-primary {
    background-color: #18a499 !important;
}

.piechart-wrapper {
    &>svg {
        overflow: visible;
    }
}

.pie-custom {
    &>g {
        transform: rotate(-90deg);
        transform-origin: center;
    }
}