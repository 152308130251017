//src/MainPages/Dashboards/CapacityCompliance/Settings
//index.js
//Zone Settings 대시보드 수정용
.setting-left{
  margin-bottom: 1rem;
}
.setting-right{
  display: flex;
  justify-content: center;
  align-items: center;
}


//src/MainPages/Dashboards/CapacityCompliance/Settings
//Components/GeofenceSettingForm.js
//GeoFenceSetting 컴포넌트 수정용
$oragne: #eb651e;
@import "../common/scrollbar.scss";

.card-body-custom{
  position: relative;
  padding: 3rem calc(1.5rem - 15px) 1rem 1.2rem;
  overflow-y: auto;

  @include scrollbar(indigo);

  & .card-body-font{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 500;
    color: #9c9c9c;

    & .info-icon-custom{
      display: block;
      color: $oragne;
    }
  }
  & .border-custom{
    width: 100%;
    height: 1px;
    background-color: rgba(255,255,255,0.3);
    margin-top: -10px;
    margin-bottom: 2.2rem;
  }
  & .row-custom{
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;

    & label{
      padding: 0;
      position: relative;
      font-size: 0.9rem;
      & i{
        position: absolute;
        bottom: -1rem;
        left: -.5rem;
      }
    }
    & .input-custom{
      color: white;
      border: none;
      background-color: #424764;
      text-align: right;
      padding: 0.2rem;
    }
    & .percent-custom{
      color: rgba(255,255,255,0.7);
      border: none;
      background-color: #424764;
      text-align: right;
      padding: 0.3rem 1rem 0.2rem 0.3rem;
      border-top-right-radius: 20px !important;
      border-bottom-right-radius: 20px !important;
    }
    & .yes-custom{
      padding: 0 0 0 1rem;
      text-align: left;
      font-size: 1rem;

      & label::before, label::after{
        top: 0.3rem;
      }
    }
    & .status-custom{

      & span{
        border: none;
        font-size: .8rem;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        width: 5rem;
      }
    }
    & .excess-label-custom{
      border: none;
      background-color: transparent;
      margin-left: .6rem;
      padding:0;
      font-size: .9rem;
      opacity: 0.5;
    }
  }
  & .row-center-custom{
    display: flex;
    justify-content: center;

    & .ok-btn-custom{
      border: 2px solid $oragne;
      background-color: transparent;
      color: $oragne;
      border-radius: 18px;
      width: 10rem;
    }
    & .ok-btn-custom:hover,
    .ok-btn-custom:focus,
    .ok-btn-custom:active{
      border: 2px solid $oragne;
      background-color: $oragne;
      color: white;
      border-radius: 18px;
      width: 10rem;
    }
  }
}