
//[대시보드-3]
//뎁스 3, Path - floor 오른쪽 보드
@import "../common/scrollbar.scss";
.path-ftb-custom{
    height: 100%;
    width: 100%;
    
    & .card-header-custom{
        font-size: 1.3rem;
        justify-content: center;
        margin-top: 1.5rem;
        height: 12%;
    }
    & .card-body-custom{
        padding: 0;
        margin: 0;
        height: 88%;
        

        & .table-custom{
            font-size: 1rem;
            margin-bottom: 2px;
            width: 100%;
            height: 100%;
            display: block;

            & .thead-custom{
                display: block;
                height: 12%;
                width: 100%;
                & tr {
                    display: table;
                    width: calc(100% - 10px);
                    & th{
                        color: #a7b4c3;
                        font-weight: normal;
                        border: none;
                        padding: 0.6rem 0 0.6rem 25px;
                        width: 50%;
                    }
                }
            }

            & .tbody-custom{
                display: block;
                height: 88%;
                width: 100%;
                overflow-y: auto;

                & tr{
                    display: table;
                    width: 100%;

                    &:nth-child(2n-1){
                        background-color: rgba(0,0,0,0.15);
                    }
                    & td{
                        padding: 0.6rem 0 0.6rem 25px;
                        width: 50%;
                        border: none;
                    }
                }
                & tr+tr{
                    border-top: 1px solid #2b2f45 !important;
                }
                @include scrollbar(indigo);
            }
        }
    }
}

@media screen and (max-width: 500px){
    .path-ftb-custom{
        & *{
            font-size: .9rem;
        }
        & .card-body-custom{
            & .table-custom{
                & .thead-custom{
                    & th{
                        color: #a7b4c3;
                        font-weight: normal;
                        border: none;
                        padding: 0.6rem 0 0.6rem 25px;
                    }
                }
                & .tbody-custom{
                    & td{
                        padding: 0.6rem 0 0.6rem 25px;
                    }
                }
            }
        }
    }
}

.interactive-td {
    cursor: pointer;
    &:hover {
        background-color:#aaaaaa !important;
    }
    &.bg-danger:hover {
        background-color: #e36161 !important;
    }
    &.bg-critical {
        //background-color: #e36161 !important;
        background-color: #d13838 !important;
    }
    &.bg-critical:hover {
        //background-color: #e36161 !important;
        background-color: #d13838d2 !important;
    }
    &.bg-warning {
        background-color: #eb651e !important;
    }
    &.bg-warning:hover {
        background-color: #eb661ed2 !important;
    }
    &.bg-caution {
        background-color: #f0ac30 !important;
    }
    &.bg-caution:hover {
        background-color: #f0ad30cc !important;
    }
    &.bg-normal {
        background-color: #18a499 !important;
    }
    &.bg-normal:hover {
        background-color: #18a498b7 !important;
    }
    &.bg-risk-critical {
        background-color: #FF3333 !important;
    }
    &.bg-risk-critical:hover {
        //background-color: #e36161 !important;
        background-color: #FF3333d2 !important;
    }
    &.bg-risk-warning {
        background-color: #FF6E0E !important;
    }
    &.bg-risk-warning:hover {
        background-color: #FF6E0Ed2 !important;
    }
    &.bg-risk-caution {
        background-color: #f0ac30 !important;
    }
    &.bg-risk-caution:hover {
        background-color: #f0ad30cc !important;
    }
    &.bg-risk-none {
        background-color: #18a499 !important;
    }
    &.bg-risk-none:hover {
        background-color: #18a498b7 !important;
    }
}

.interactive-td-danger {
    cursor: pointer;
    &:hover {
        background-color: #e36161 !important;
    }
}