@import "../../main/common/common.scss";

//페이지 컴포넌트 섹션들
@include media-breakpoint-down(xl) {
    .board-custom{
        height: 60vh;
        //border: 1px solid red;
    }
}
@include media-breakpoint-down(lg) {
    .board-custom{
        height: auto;
        //border: 1px solid blue;
    }
    .board-fixed{
        height: 60vh;
    }
}
@include media-breakpoint-down(xs) {
    .board-custom{
        height: auto;
        //border: 1px solid yellow;
    }
    .board-fixed{
        height: 80vh;
    }
}


//Dashboard -3
//Contact Tracing - Tracing List - Search
@include media-breakpoint-down(xl) {
    & .page-header-custom{

        & .page-title-wrapper{

            & .page-title-heading-custom{
                display: flex;
                justify-content: space-between;
                width: 100%;

                & .page-title-font-custom{
                    margin-left: 5px;
                }

                & button{
                    margin-left: 1rem;
                }

            }
        }
    }

    .list-card-header-custom{
        height: 3rem;
        padding-top: 1rem;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
    }
    .list-card-body-custom{
        //padding: 1rem;
        text-align: left;
    }
    .tracing-search-body{
        background-color: rgba(0,0,0,0.1);
        padding: 1.5rem 2.5rem .5rem;

        & label{
            text-align: left;
            color: $indigo-font;
            padding: 0;
        }
        & .row .form-group {
            margin-right: 1rem;

            & ul{
                border: none;
                background-color: white;

                & a{
                    //color: white;
                    padding: 0.5rem 0.7rem;
                    border-bottom: 1px solid rgba(124, 89, 89, 0.1);
                }
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    .tracing-search-body{
        background-color: rgba(0,0,0,0.1);
        padding: 2.5rem 4rem;

        & label{
            text-align: left;
            color: $indigo-font;
            padding: 0;
            margin: 0 0 .5rem 1rem;
        }
        & .row .form-group {
            margin-right: 0;

            & ul{
                border: none;
                background-color: white;

                & a{
                    //color: white;
                    padding: 0.5rem 0.7rem;
                    border-bottom: 1px solid rgba(0,0,0,0.1);
                }
            }
        }
    }
}
@include media-breakpoint-down(sm) {
    .page-header-custom{

        & .page-title-wrapper{

            & .page-title-heading-custom{
                display: block;
                width: 100%;

                & .page-title-font-custom{
                    margin-left: 0;
                    margin-bottom: 1rem;
                }
                & > span{
                    width: 100%;
                    display: block;
                }
                & a > button{
                    margin: 1.5rem 0 .5rem 0;
                    min-width: 30%;
                }
            }
        }
    }

    .list-card-body-custom{
        text-align: center;
    }
}
@include media-breakpoint-down(xs) {
    
    .list-card-header-custom{
        height: auto;
        padding: 0;
    }

    .tracing-search-body{
        background-color: rgba(0,0,0,0.1);
        padding: 1rem;

        & label{
            text-align: left;
            color: $indigo-font;
            padding: 0;
            margin: 0 0 .5rem 1rem;
        }
        & .row .form-group {
            margin-right: 0;

            & div{
                padding-right: 0;
            }
        }
    }
}


//Dashboard -4
//Disease Template Setting - whole board
.dts-custom{

    & label{
        color: $indigo-font;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
    }
    @include media-breakpoint-down(xs) {

        & .form-group > label{
            padding: .5rem 1rem;
        }
        & .form-row{
            & label{
                padding: 0 !important;
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    
    .dts-custom{
        & .page-header-custom{

            & .page-title-wrapper{
    
                & .page-title-heading-custom{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
    
                    & .page-title-font-custom{
                        margin-left: 5px;
                    }
    
                    & button{
                        margin-left: 1rem;
                    }
    
                }
            }
        }
    
    
        & .btn-bottom-wrap{
            margin: 2rem 0;
            padding: 0;
            justify-content: center;
    
            & .btn-bottom{
                border-radius: 0;
                font-size: 1.3rem;
                font-weight: normal;
                border-radius: 2rem;
                border: 2px solid;
                box-sizing: border-box;
                margin: 0 1rem;
                background-color: transparent;
                
            }
            & .btn-bottom.btn-orange{
                border-color: $orange;
                color: white;
                background-color: $orange;
                //color: orange;
                //background-color: rgba(255,150,0,0.2);
                &:hover,:active{
                    background-color: orange;
                    border-color: orange;
                }
            }
            & .btn-bottom.btn-lightorange{
                border-color: $rotten-orange;
                color: $rotten-orange;
                &:hover,:active{
                    border-color: orange;
                    color: orange;
                    background-color: transparent;
                }
            }
            & .btn-bottom.btn-red{
                border-color: rgba(255, 105, 105, 0.849);
                color:  rgba(255, 105, 105, 0.849);
                //background-color: rgba(255,0,0,0.3);
                &:hover,:active{
                    border-color: orange;
                    color: orange;
                    background-color: transparent;
                }
            }
        }
    }


    .btn-bottom-wrap{
        margin: 2rem 0;
        padding: 0;
        justify-content: center;

        & .btn-bottom{
            border-radius: 0;
            font-size: 1.3rem;
            font-weight: normal;
            border-radius: 2rem;
            border: 2px solid;
            box-sizing: border-box;
            margin: 0 1rem;
            background-color: transparent;
            
        }
        & .btn-bottom.btn-orange{
            border-color: $orange;
            color: white;
            background-color: $orange;
            //color: orange;
            //background-color: rgba(255,150,0,0.2);
            &:hover,:active{
                background-color: orange;
                border-color: orange;
            }
        }
        & .btn-bottom.btn-lightorange{
            border-color: $rotten-orange;
            color: $rotten-orange;
            &:hover,:active{
                border-color: orange;
                color: orange;
                background-color: transparent;
            }
        }
        & .btn-bottom.btn-red{
            border-color: rgba(255, 105, 105, 0.849);
            color:  rgba(255, 105, 105, 0.849);
            //background-color: rgba(255,0,0,0.3);
            &:hover,:active{
                border-color: orange;
                color: orange;
                background-color: transparent;
            }
        }
    }
}

@include media-breakpoint-down(md) {

    .dts-custom{
        .page-header-custom{

            & .page-title-wrapper{
    
                & .page-title-heading-custom{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
    
                    & .page-title-font-custom{
                        margin-left: 5px;
                    }
    
                    & button{
                        margin-left: 1rem;
                    }
                }
            }
        }
    
        .container-mobile-custom {
            padding: 0;
    
            & .card-body {
                padding: 1.5rem;
            }
        }
        .page-title-wrapper {
    
            & .page-title-heading-custom {
                display: block;
                width: 100%;
    
                & .page-title-font-custom {
                    margin-left: 0;
                    margin-bottom: 1rem;
                }
    
            }
        }
        .page-header-custom{
    
            & .page-title-wrapper{
    
                & .page-title-heading-custom{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    display: block;
                }
    
                & a > button{
                    margin: 1.5rem 0 .5rem 0;
                    min-width: 40%;
                }
            }
        }
    }
}
@include media-breakpoint-down(xs) {

    .dts-custom{
        & .btn-bottom-wrap{
            margin: 2rem -1.25rem;
            padding: 0;
    
            & .btn-lightorange, .btn-orange{
                font-size: 1rem;
                font-weight: normal;
                margin: 0 .5rem;
            }

            & .btn-bottom.btn-red{
                font-size: 1rem;
                font-weight: normal;
                flex: 0 0 calc(83.3333% + 1rem);
                max-width: calc(83.3333% + 1rem);
            }
        }
    }

    .btn-bottom-wrap{
        margin: 2rem -1.25rem;
        padding: 0;

        & .btn-lightorange, .btn-orange{
            font-size: 1rem;
            font-weight: normal;
            margin: 0 .5rem;
        }

        & .btn-bottom.btn-red{
            font-size: 1rem;
            font-weight: normal;
            flex: 0 0 calc(83.3333% + 1rem);
            max-width: calc(83.3333% + 1rem);
        }
    }
}

.page-header-custom{
    background-color: transparent !important;
    padding: 10px 10px 20px 10px;
    margin: 0;

    & .page-title-wrapper{

        & .page-title-heading-custom{

            & .page-title-font-custom{
                font-weight: bold;
            }
            & a:hover{
                text-decoration: none;
            }
        }
    }
}