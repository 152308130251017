@import "src/assets/main/common/common";

.ReactTable .pagination-bottom {
  background-color: $indigo-2;
}

.ReactTable {
  .-pagination {
    padding: 5px;

    .-previous, .-next {
      display: flex;
      justify-content: center;
      align-items: center;

      .-btn.btn-pagination {
        background-color: $orange;
        border-color: $orange;
        border-radius: 50%;
        min-width: initial;
        width: 35px;
        height: 35px;
        color: #fff;
        //padding: .5rem 1.5rem;
        //display: inline-flex;
        //align-items: center;
        //justify-content: center;
        //position: relative;
        //min-width: 5rem;
      }
      .-btn.btn-pagination:not([disabled]):hover {
        background-color: orange;
        border-color: orange;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .ReactTable .-pagination .-center {
    display: flex;
  }
}