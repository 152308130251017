.legend-box-wrapper {
    position: absolute;
    top: 15px;
    left: 30px;
}

.legend-box {
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 2px;
    padding: .5rem !important;
    opacity: 0.8;
    & dl {
        margin-bottom: 0;
    }
    & dd {
        margin-bottom: 3px;
    }
    & i,span {
        font-size: 1rem;
    }
    & i {
        margin-right: 1rem;
    }
    & span {
        color: #000;
    }
}
.filter-box-wrapper {
    position: absolute;
    top: 15px;
    right: 45px;
    width: 280px;
    //@media (max-width: 900px) {
    //    width: 500px;
    //}
    //@media (max-width: 600px) {
    //    width: 280px;
    //}
}
.filter-box {
    background-color: rgba(60, 60, 100, 0.5);
    color: #fff;
    padding: .5rem;
    border-radius: 0.25rem;
}

.control-box-wrapper {
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: right;
}

.control-box {
    text-align: start;
    padding: 0 .5rem;
    & dl {
        margin-bottom: 0;
    }
    & dd {
        margin-bottom: 3px;
    }
    & i,span {
        font-size: 1.25rem;
    }
    & i {
        margin-right: 1rem;
    }
    & span {
        color: #000;
    }
}

.node-detail-wrapper {
    position: absolute;
    bottom: 35px;
    right: 60px;
    @media (max-width: 400px) {
        & {
            width: calc(100% - 30px);
            right: 15px;
            .card-body {
                width: 100%;
            }
        }
    }
    //background-color: rgba(255, 255, 255, 0.9);
    .card {
        background-color: #8c92b2;
        button, button:hover {
            background-color: inherit;
            color: inherit;
        }
    }
    .card-body {
        width: 350px;
        padding: 0.25rem;
        font-weight: bold;
    }
    .card-header {
        display: flex !important;
        background-color: inherit;
        color: inherit;
        text-transform: none;
        height: initial;
        justify-content: space-between;
        padding: 5px;
    }
    .header-container {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .card.root {
        background-color: #12b712;
        color: #eee;
    }
    .card.risk-critical {
        background-color: #d13838;
        color: #eee;
    }
    .card.risk-warning {
        background-color: #eb651e;
        color: #eee;
    }
    .card.risk-caution {
        background-color: #f0ac30;
    }
}

.tracing-graph {
    width: 100%;
    overflow: hidden;
    min-height: 67vh;
    background-color: #252c3b;
    @media (max-width: 768px) {
        & {
            height: 100vw;
        }
    }
}

.node {
    cursor: pointer;
}

.link {
    fill: none;
    stroke: #a3a3a3;
    stroke-width: 2px;
}

.disease-icon {
    width: 100%;
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    fill: #fff;
    pointer-events: none;
}

.text {
    pointer-events: none;
}

@keyframes rotate_image{
    100% {
        transform: rotate(360deg);
    }
}

.text-btn.data-pending {
    animation: rotate_image 1s linear infinite;
    pointer-events: none;
    font-size: 20px;
    fill: #fff;
}


.text-btn {
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
}

.contacts-cnt-label {
    width: 26px;
    text-align: center;
    background-color: #444;
    color: #fff;
    border-radius: 20%;
    font-weight: 500;
    padding: 1px 0;
    &:hover {
        background-color: #999999;
    }
}