.card-custom{
    background-color: #252c3b;
    color: white;
    padding: 25px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 120px;
}

.total-num-font {
    color: #a7b4c3;
    font-family: NotoSans;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2;
}

.total-num-stack {
    font-size: 1.2rem;
    line-height: 1;
    font-weight: bold;
}
@media (max-width: 1200px) {
    .total-num {
        line-height: inherit;
    }
}