.col-15,
.col-sm-15,
.col-md-15,
.col-lg-15,
.col-xl-15,
.col-xxl-15 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-15 {
  width: 20%;
  float: left;
}
@media (min-width: 576px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 768px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}
//@media (min-width: 1200px) {
@media (min-width: 1300px) {
  .col-xl-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1300px) {
  .col-xxl-15 {
    width: 20%;
    float: left;
  }
}