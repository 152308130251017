.select-custom {
  padding-right: 5px;
  width: 100%;
}

.dropdown-custom {
  padding-right: 5px;
  display: flex;
  align-items: center;
}
.check-custom {
  & > div {
    padding-top: .5rem;
  }
}