.login-bg-custom {
    background-color: #181b1f;
}
.app-login-box-custom{
    width:800px;
}
.login-title-custom{
    background-size:contain;
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: white;
    font-family: NotoSans;
}
.modal-dialog-custom{
    box-shadow: none;
}
.modal-content-custom{
    border: none;
}
.modal-body-custom{
    background-color: #252c3b;
    /* 패딩값때문에 튕기는 현상발생 */
    /* 미디어쿼리때문일듯 */
    padding: 15px 30px;
}


/* Login 글씨, 위계때문에 클래스로적지않음 */
.modal-test h4 div {
    opacity: 1;
    color: white;
    font-family: NotoSans;
    font-weight: bold;
    font-size: 1.2rem;
}

.input-label{
    color: #a7b4c3;
    font-family: NotoSans;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 5px;
}
.input-custom {
    display: inline;
    width: 100%;
    height: 36px;
    margin: 0;
    border-radius: 20px;
    border: none;
    /* important 중복으로 안먹힘. 수정필요 */
    color: white !important;
    background-color: #424764 !important;
}
.input-custom,
.input-custom:hover,
.input-custom:focus,
.input-custom:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px #424764 inset;
    -webkit-text-fill-color: white;
    caret-color: white;
}
.input-custom::placeholder {
    -webkit-text-fill-color: #b1b1b1;
}

.btn-custom{
    background-color: transparent;
    border-radius: 18px;
    border: solid 2px #eb651e;
    width: 150px;
    height: 35px;
    padding: 0;

    font-family: NotoSans;
    font-size: 15px;
    font-weight: 500;
    color: #eb651e;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active {
    color: white;
    background-color: #eb651e !important;
    border: 1px solid #eb651e !important;
}
.modal-footer-custom {
    background-color: #252c3b;
    border-top: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px 0 40px 0;
}



.beta-custom{
    text-align: center;
    margin: 30px 0 5px 0;
    opacity: 0.5;
    color: white;
    font-family: NotoSans;
    font-size: 13px;
}
.copyright-custom{
    color: white;
    font-family: NotoSans;
    font-size: 14px;
}