.header-userbtn-custom{

    //UserBox 버튼
    & .userbox-btn-custom{
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: rgba(255,255,255,0.8);

        & .userbox-btn-txt-custom{
            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
            max-width: 220px;
            padding-bottom: 0.2rem;

            opacity: 0.8;
            font-family: NotoSans;
            font-weight: 500;
            transition: all .2s;

            &:hover{
                opacity: 1;
                transition: all .2s;
            }
        }
    }

    //UserBox 드롭다운
    & .dmh-custom{
        margin: 0;

        & .dmhi-custom{
            margin: 0;
            padding: 0;
            border-radius: 0.25rem;

            & .widget-name-custom{
                font-weight: normal;
                max-width: calc(100% - 120px);

                & .widget-heading-custom{
                    color: white;
                    font-weight: normal;
                    opacity: 0.8;
                    text-overflow: ellipsis;
                    overflow-x: hidden;
                    white-space: nowrap;
                }
            }
        }
    }
}