

@include media-breakpoint-down(xl) {
  .profile-card-custom{
    border-radius: 10rem;

    & .profile-card-wrap{
      justify-content: center;
      & .info-label-grp{
        //min-width: 30%;
        //max-width: 30%;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .profile-card-custom{
    border-radius: 10rem;

    & .info-label-grp{
      //min-width: 23%;
      //min-width: 30%;
    }
  }
}
@include media-breakpoint-down(sm) {
  .profile-card-custom{
    border-radius: 2rem;

    & .info-label-grp{
      //min-width: 30%;
      max-width: 100%;
    }
  }
}
@include media-breakpoint-down(xs) {
  .profile-card-custom{
    border-radius: .25rem;

    & .profile-card-wrap{
      justify-content: left;
      & .info-label-grp{
        //min-width: 100%;
        max-width: 100%;
      }
    }
  }
}

.profile-card-custom{
  width: 100%;
  font-size: 1rem;

  & .profile-card-wrap{
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    & .info-label-grp{
      color: rgba(255,255,255,0.5);
      padding: .5rem 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      & div{
        font-size: 1rem;
        font-weight: normal;
        margin-bottom: 0;
        text-transform: none;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;

        color: white;
        background-color: transparent;
        padding: 0;
        overflow: hidden;
      }
    }

  }
}