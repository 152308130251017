.modal {
    margin: 0 1rem;
    max-width: initial !important;
    width: 600px;
}
.modal-wrap {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
}


// .pop-zone-details {
//     position: fixed;
//     top: 10%;
//     left: 50%;
//     z-index: 1100;
//     max-width: 650px;
//     min-width: 550px;

//     font-family: NotoSans;
//     color: white;
//     font-size: 1rem;
//     font-weight: 300;
//     padding: 1rem;

//     & *{
//         font-family: NotoSans;
//         font-weight: normal;
//     }

//     //팝존-제목칸
//     & .pop-zone-header{
//         align-items: baseline;
//         & button{
//             position: absolute;
//             right: 1.25rem;
//             top: 2rem;
//             font-weight: 100;
//             font-size: 3rem;
//             line-height: 0;
//             text-shadow: none;
//         }
//         & .pop-zone-header-txt{
//             font-size: 1.3rem;
//             font-weight: bold;
//         }
//         & .pop-zone-header-datetxt{
//             opacity: 0.7;
//         }
//     }

//     //팝존-첫번째줄
//     & .pop-zone-authorized{
//         background-color: rgba(255,255,255,0.05);
//         border-radius: 0.25rem;
//         width: 104%;
//         margin-left: -2%;
//         color: white;
//         font-size: 1rem;
//         font-weight: 900;
//         position: relative;
//         & *{
//             border: none;
//         }
//         & tr{
//             & th{
//                 width: 100%;
//                 width: 50%;
//                 padding: 0.7rem;
//             }
//             & div{
//                 position: absolute;
//                 display: inline-block;
//                 width: 1px;
//                 height: 16px;
//                 background-color: rgba(255,255,255,0.3);
//                 top: 50%;
//                 transform: translateY(-50%);
//             }
//             & span{
//                 margin-left: 15px;
//             }
//         }
//     }

//     .expanded-daily-graph {
//         width: 95% !important;
//         position: fixed !important;
//         top: 50% !important;
//         left: 50% !important;
//         transform: translate(-50%, -50%) !important;
//     }
//     .btn-max-window {
//         display: none;
//     }
// }


// @media screen and (min-width: 991.98px) {
//     .pop-zone-details {
//         border: 10px solid red;
//         position: fixed;
//         top: calc(50% + 60px);
//         left: 50%;
//         transform: translate(-50%, calc(-50% - 60px))!important;
//         width: 95%;
//         height: 75vh;
//         padding: 1rem;
//     }
// }



.rt-font-8 {
    font-size: 0.8rem;
}

.img-hospital {
    //width: 4000px !important;
    //height: 3273px !important;
}