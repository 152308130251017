//EventAlarm.js


.notification-wrap{

  & .notification-header{
    background-color: rgba(0,0,0,0.13);
    color: white;
    padding-top: 2rem;
  }
}


//AlarmList.js
.alarm-item {
  &>div {
    display: flex;
    align-items: center;
  }
  &>div>span {
    top: initial;
  }
  & .badge-warning {
    color: #fff !important;
    background-color: #ec8909 !important;
  }
  & .badge-caution {
    color: #fff !important;
    background-color: #ffde32 !important;
  }
}

.notification-body{
  color: white;
  padding-bottom: 1rem;
  height: 300px;
  
  & .timeline-dot{
    width: 18px;
    height: 18px;
    border-radius: 100px;
    position: relative;

    &::after{
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 60%;
        height: 60%;
        border-radius: 100px;
        background-color: #3a3f5d;
    }
  }
  & .timeline-title-custom{
      text-transform: none;
      font-family: NotoSans;
      font-weight: normal;
      line-height: 1.5;
      margin-bottom: 0.7rem;

      & .timeline-date{
        color: #a7b4c3;
        line-height: 2;
      }
  }
}