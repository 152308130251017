//테스트용
.taejeong{
    border: 1px solid red;
}


//공통값
$orange:#eb651e;
$rotten-orange: #a38e83;
$indigo-1: #3a3f5d; //인풋 창
$indigo-2: #252c3b; //페이지 bg
$indigo-3: #2b2f45; //헤더, 사이드바 bg, 드롭다운 버튼 컬러
$indigo-4: #181b1f; //페이지 전체 다크 bg
$indigo-5: #1d2333; //헤더의 로고박스bg에만
$indigo-6: #505773; //클릭되지 않은, 밝은 인디고 버튼
$indigo-7: #343954; //테이블 리스트 홀수칸
$indigo-8: #424764; //인풋 컬러
$indigo-font: #a7b4c3; //인디고theme 전용 폰트컬러


$icon-size: 1.5rem;
$icon-size-big: 3rem;

$radius-1: 0.25rem;
$radius-2: 0.5rem;
$radius-3: 1.25rem;
$radius-4: 1.5rem;
$radius-5: 2rem;
$radius-10: 100px;

//공통 아이콘
%icon-center{
    background-size: contain;
    background-repeat: no-repeat;
    width: 50%;
    height: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translateX(-50%) translateY(-50%);
}
%icon-setting{
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    width: $icon-size;
    height: $icon-size;
}
%icon-one{
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.icon-wrapper-big-custom{
    display: inline-block;
    vertical-align: top;
    margin: .1rem 1rem 0 0;
    position: relative;
    width: $icon-size-big;
    height: $icon-size-big;

    background-color: rgba(255,255,255,0.1);
}
.icon-refresh{
    background-image: url("images/icon-refresh.svg");
    filter: invert(100%);
    @extend %icon-setting;
    width: .9rem;
    height: .9rem;
}
.icon-refresh2{
    background-image: url("images/icon-refresh-material.svg");
    filter: invert(100%);
    transform: scale(0.9);
    @extend %icon-setting;
}

.icon-user{
    background-image: url("images/profile.png");
    @extend %icon-setting;
}
.icon-plus{
    font-size: 1.5rem;
    margin-right: 1.5rem;
    line-height: 0;
    position: relative;

    &::after{
        content:'+';
        display: inline-block;
        position: absolute;
        top: -.2rem;
    }
}
.icon-down{
    margin-left: 1rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
    border-radius: 20rem;
    border: 2px solid $orange;
    position: relative;

    &::after{
        content:'';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 0;
        height: 0;
        border-top: .4rem solid $orange;
        border-bottom: .4rem solid none;
        border-right: .3rem solid transparent;
        border-left: .3rem solid  transparent;
    }
    &:hover{
        background-color: rgba(255, 255, 0, 0.1);
        border-color: orange;
        transition: all .3s;
    }
}

.icon-tracing{
    background-image: url("images/icon-tracing.svg");
    //filter: hue-rotate(90deg);
    @extend %icon-center;
}
.icon-space-tracing{
    background-image: url("images/icon-areatracing.svg");
    //filter: hue-rotate(90deg);
    @extend %icon-center;
}
.icon-map{
    background-image: url("images/icon-map.svg");
    @extend %icon-center;
}
.icon-people{
    background-image: url("images/icon-people.svg");
    @extend %icon-center;
}

.icon-popup{
    background-image: url("images/icon-popup.svg");
    display: inline-block;
    vertical-align: middle;
    margin-left: 1rem;
    background-repeat: no-repeat;
    background-size: contain;
    width: 2rem;
    height: 2rem;
    border-radius: 20rem;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
}
@include media-breakpoint-down(md) {
    .icon-popup{
        position: absolute;
        right: 1rem;
    }
}




.icon-checked{
    background-image: url("images/icon-checked-circle.svg");
    @extend %icon-setting;
}
.icon-nonchecked{
    background-image: url("images/icon-nonchecked-circle.svg");
    @extend %icon-setting;
}
.icon-download{
    background-image: url("images/icon-download.svg");
    background-repeat: no-repeat;
    width: 1.3rem;
    height: 1.3rem;
    margin-right: .5rem;
}
.icon-search{
    background-image: url("images/icon-search.svg");
}
.icon-trash{
    background-image: url("images/icon-trash.svg");
    filter:  brightness(0) invert(1);
    @extend %icon-one;
}

.lnr-magnifier.bgpop{
    // -webkit-transform: rotate(180deg);
    // -ms-transform: rotate(180deg);
    // transform: rotate(180deg);
    &::after{
        content: '';
        width: 0;
        height: 0;
        border-top:.3rem solid white;
        border-bottom:.3rem solid none;
        border-right: .3rem solid transparent;
        border-left: .3rem solid  transparent;
        display: inline-block;
        vertical-align: middle;
        margin: 0 .5rem .3rem .3rem;
    }
}

//공통 컬러
.bg-indigo{
    background-color: $indigo-1 !important;
    border: none !important;
    border-radius: .25rem;
}

.bg-indigo2{
    background-color: $indigo-2 !important;
    border: none !important;
    border-radius: .25rem;
}