@import "../common/scrollbar.scss";

@include media-breakpoint-down(xl) {
    .pop-zone-details {
        position: fixed;
        top: 10%;
        left: 50%;
        z-index: 1100;
        max-width: 650px;
        min-width: 550px;

        & *:not(i) {
            font-family: NotoSans;
            color: white;
            font-weight: normal;
            background-color: transparent;
            border: none !important;
        }

        //팝존-제목칸
        & .toast-header{
            height: 12%;
            font-size: 1.3rem;
            align-items: baseline;
            padding: 1.5rem 2rem;
            & button {
                //position: absolute;
                //right: 1.25rem;
                //top: 2rem;
                font-weight: 100;
                font-size: 3rem;
                line-height: 0;
                text-shadow: none;
            }
        }

        //팝존-바디칸
        & .toast-body{
            height: 88%;
            padding: 0;
            font-size: 1rem;


            //Daily Contacts 그래프용 서브 제목란
            & .table{
                & > div{
                    line-height: 0;
                    padding: 1rem 1.5rem 0 2rem;
                    & > span{
                        border-left: 2px solid white !important;
                        border-right: 2px solid white !important;
                        padding: 0 1rem;
                        & p{
                            display: inline-block;
                            vertical-align: baseline;
                            font-weight: bold;
                        }
                        & div{
                            display: inline-block;
                            vertical-align: baseline;
                            margin-left: 1rem;
                            font-weight: bold;
                            font-size: 2rem;
                        }
                    }
                }
            }


            & .ReactTable{
                
                & .rt-table{
                    overflow-y: hidden;
                    overflow-x: auto;
                    display: block;
                    height: 100%;
                    width: 100%;
                    & .rt-thead{
                        overflow-x: hidden;
                        & .rt-tr{
                            padding: 0 1.5rem;
                            & .rt-th{
                                & *{
                                    text-align: center;
                                    color: $indigo-font;
                                }
                            }
                        }
                    }
                    & .rt-tbody{
                        overflow-y: auto;
                        overflow-x: hidden;
                        min-width: 0 !important;
                        & .rt-tr-group{
                            &:nth-of-type(2n-1){
                                background: rgba(0,0,0,0.1);
                            }
                            & .rt-tr{
                                padding: .15rem 1.5rem;
                                & .rt-td{
                                    text-align: left;
                                    border: none;
                                    min-width: none;
                                    & *{
                                        text-align: left;
                                    }
                                }
                            }
                        }
                        @include scrollbar(indigo);
                    }
                    @include scrollbar(indigo);
                }
                
                
                & .rt-noData{
                border-radius: 0.25rem;
                background-color: rgba(0,0,0,0.3);
                color: white;
                width: 70%;
                padding: 3rem 0;
                text-align: center;
                }
            }
        }
    }
}
@include media-breakpoint-down(sm) {
    .pop-zone-details {
        position: fixed;
        top: 5%;
        z-index: 1100;
        max-width: 96%;
        min-width: 96%;
        //height: 70%;

        & .toast-header{
            height: 14%;
            font-size: 1.3rem;
            align-items: baseline;
            padding: .8rem 2rem 2rem 2rem;
            & button{
                //position: absolute;
                //right: 1rem;
                //top: 1.5rem;
                font-weight: 100;
                font-size: 3rem;
                line-height: 0;
                text-shadow: none;
            }
        }

        & .toast-body{
            height: 86%;
            *{font-size: 0.8rem;}
            & .ReactTable{
                max-height: 100%;
                & .rt-table{
                    overflow-y: hidden;
                    overflow-x: auto;
                    display: block;
                    height: 100%;
                    width: 100%;
                    & .rt-thead{
                        overflow-x: hidden;
                        width: 100%;
                        & .rt-tr{
                            padding: 1rem 0;
                            & .rt-th{
                                padding: 0;
                            }
                        }
                    }
                    & .rt-tbody{
                        width: 100%;    //fit-content;
                        min-width: 0 !important;
                        overflow-y: auto;
                        overflow-x: hidden;
                        & .rt-tr-group{
                            width: 100%;
                            &:nth-of-type(2n-1){
                                background: transparent;
                            }
                            &:nth-of-type(2n-1) .rt-td{
                                background: rgba(0,0,0,0.1);
                                //background: transparent;
                            }
                            & .rt-tr{
                                width: 100%;
                                padding: .15rem 0;
                                
                                & .rt-td{
                                    border: none;
                                    min-width: none;
                                    padding: 0.55rem 2rem;

                                    & div{
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                        @include scrollbar(indigo);
                    }
                    @include scrollbar(indigo);
                }
            }
        }
    }
}

// .pop-zone-details {
//     position: fixed;
//     top: 10%;
//     left: 50%;
//     z-index: 1100;
//     max-width: 650px;
//     min-width: 550px;

//     font-family: NotoSans;
//     color: white;
//     font-size: 1rem;
//     font-weight: 300;
//     padding: 1rem;

//     & *{
//         font-family: NotoSans;
//         font-weight: normal;
//     }

//     //팝존-제목칸
//     & .toast-header{
//         font-size: 1.3rem;
//         align-items: baseline;
//         & button{
//             position: absolute;
//             right: 1.25rem;
//             top: 2rem;
//             font-weight: 100;
//             font-size: 3rem;
//             line-height: 0;
//             text-shadow: none;
//         }
//         & .pop-zone-header-txt{
//             font-size: 1.3rem;
//             font-weight: bold;
//         }
//         & .pop-zone-header-datetxt{
//             opacity: 0.7;
//         }
//     }

//     //팝존-첫번째줄
//     & .pop-zone-authorized{
//         background-color: rgba(255,255,255,0.05);
//         border-radius: 0.25rem;
//         width: 104%;
//         margin-left: -2%;
//         color: white;
//         font-size: 1rem;
//         font-weight: 900;
//         position: relative;
//         & *{
//             border: none;
//         }
//         & tr{
//             & th{
//                 width: 100%;
//                 width: 50%;
//                 padding: 0.7rem;
//             }
//             & div{
//                 position: absolute;
//                 display: inline-block;
//                 width: 1px;
//                 height: 16px;
//                 background-color: rgba(255,255,255,0.3);
//                 top: 50%;
//                 transform: translateY(-50%);
//             }
//             & span{
//                 margin-left: 15px;
//             }
//         }
//     }

//     .expanded-daily-graph {
//         width: 95% !important;
//         position: fixed !important;
//         top: 50% !important;
//         left: 50% !important;
//         transform: translate(-50%, -50%) !important;
//     }
//     .btn-max-window {
//         display: none;
//     }
// }



.pop-zone-details.toast .toast-header .close {
    float: right;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.pop-zone-details .summary-info {
    margin-bottom: 1rem;
}

.pop-zone-details .contactlist-wrap {
    border-top: 1px solid #fff !important;
}