.tracing-log {
  border-radius: 50%;
  opacity: .5;
  //box-shadow: 0px 0px 15px 7px #d92550;
}

.tracing-log:hover {
  opacity: 1;
}

.map-img-overlay {
  display: none;
}

.zone-name-label {
  font-size: 1rem;
  font-weight: bold;
  //color: #eee;
  text-shadow: -1px 0 #F2F1F6, 0 1px #F2F1F6, 1px 0 #F2F1F6, 0 -1px #F2F1F6;
  word-break: keep-all;
  width: initial !important;
  height: initial !important;
}

.zone-name-label {
  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translate(-45%, -25%);
  }
}

.geofence-polygon {
  &.bg-risk-critical {
    //fill: #f73737 !important;
    fill: #FF3333 !important;
  }
  &.bg-risk-warning {
    fill: #FF6E0E !important;
  }
  &.bg-risk-caution {
    fill: #ffde32 !important;
  }
  &.bg-risk-none {
    fill: #18a499 !important;
  }
  &.bg-normal {
    fill: #18a499 !important;
  }
  &.bg-none {
    fill: #808182 !important;
  }
}

.tracing-line {
  stroke: #d92550;
  stroke-width: 2;
}
