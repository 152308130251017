.map-custom {
  height: 100%;
  width: 100%;
  border-radius: 0.25rem;
  background-color: $indigo-2;
  text-align: center;
  & .leaflet-control-zoom {
    //& a, & a:hover {
    //  padding: 0.2rem 0.5em !important;
    //  width: 100% !important;
    //  height: 100% !important;
    //  background-color:rgba(255,255,255, 0.7) !important;
    //  color: #181b1f !important;
    //  border-bottom-color:rgba(60, 60, 100, 0.5) !important;
    //}
    //& a:hover {
    //  background-color:rgba(255,255,255, 0.9) !important;
    //}
  }

  & .geofence-name-label {
    font-size: 1rem;
    font-weight: bold;
    text-shadow: -1px 0 #f2f1f6, 0 1px #f2f1f6, 1px 0 #f2f1f6, 0 -1px #f2f1f6;
    word-break: keep-all;
    width: initial !important;
    height: initial !important;

    background-color: initial;
    border: initial;
    box-shadow: initial;
    padding: unset;
    &:before {
      content: none;
    }
  }
}

.leaflet-tooltip.geofence-name-label {
  font-size: 1rem;
  font-weight: bold;
  text-shadow: -1px 0 #f2f1f6, 0 1px #f2f1f6, 1px 0 #f2f1f6, 0 -1px #f2f1f6;
  word-break: keep-all;
  width: initial !important;
  height: initial !important;

  background-color: initial;
  border: initial;
  box-shadow: initial;
  padding: unset;
  &:before {
    content: none;
  }
}