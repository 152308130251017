
.graph-name-label-custom{

    & > div{
        background-color: rgba(60,60,100,0.5);
        color: white;
        border-radius: 0.25rem;
        border: none;
        padding: 1rem .7rem;
        opacity: 1;
        
        & i{
            margin-right: .5rem;
        }
        & span{
            color: white;
            font-size: 0.8rem;
        }
    }
}
