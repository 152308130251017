@import "../common/scrollbar.scss";


.select-area {
    width: 130px;
    height: 36px;
    display: inline-block;
    background-color: $indigo-1;
    border-radius: $radius-4;

    & * {
        color: #fff;
        cursor: pointer;
        border: none !important;
        box-shadow: none !important;
        border-radius: $radius-4;
    }
    &:hover{
        background-color: $gray-600;
        transition: all .2s;
    }

    //버튼 부분
    & .rw-widget-container{
        border-radius: $radius-4;
        background-color: initial;
        padding: 0 0.3rem;
        height: 100%;

        font-size: 0.8rem;
        font-family: NotoSans;
        font-weight: 500;

        transition: all .2s;
    }
    // 드롭다운 부분
    & .rw-popup-container{
        background-color: $indigo-1;
        border-radius: $radius-2;
        box-shadow: 0 0 1rem rgba(0,0,0,0.3) !important;

        // 드롭다운 부분 - floor 드롭다운용
        & .rw-popup-transition{
            background-color: inherit;
            margin: 0;
            padding: 0;
            border: none;
            & .rw-popup{
                background-color: inherit;
                padding: .5rem 0;
                border-radius: $radius-2;
            }
        }

        & .rw-list{
            // 드롭다운 부분 - 스크롤바
            @include scrollbar(indigo);
        }
    }


    & li:hover{
        background-color: #222538;
        color: white;
    }
}

.span-custom{
    color: white;
    font-weight: bold;
    font-size: 1rem;
    margin-right: 10px;
}

.typeahead-custom .dropdown-menu {
    background-color: #fff;
}